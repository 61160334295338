import BenefitCalculator from "components/elements/buttons/BenefitCalculator";
import ShowAll from "components/elements/buttons/ShowAll";
import LabelledTitle from "components/elements/labels/LabelledTitle";
import ExtrasUsageTile from "components/sections/overview/ExtrasUsageTile";
import { benefitLimitHelpers } from "libs/benefitLimitHelpers";
import gtagEvent from "libs/gtagEvent";
import React, { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import ReactPlaceholder from "react-placeholder";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { setBenefitCalculatorModalVisible, thunkUpdatePreferences } from "store";


const OutputExtraTiles = ({ extrasData, handleExtraClick }) => {
  const [topLevelExtras, setTopLevelExtras] = useState([]);
  const selectedUserId = useSelector((state) => state.selectedUser);
  const preferences = useSelector((state) => state.userSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    const limits = benefitLimitHelpers(
      extrasData,
    ).getMovableGridCurrentUserBenefits(selectedUserId, preferences);

    if (limits) setTopLevelExtras(limits);
  }, [selectedUserId, extrasData]);

  const updateList = (newState) => {
    if (newState.length === 0) return;
    setTopLevelExtras(newState);

    // Now send list back to api
    let orderedCodes = [];
    newState.forEach((benefit) => {
      orderedCodes.push(benefit.SpecialityClassCode);
    });
    dispatch(thunkUpdatePreferences({ extrasGridOrder: orderedCodes }));
  };

  return (
    <>
      <ReactSortable
        list={topLevelExtras}
        setList={updateList}
        className="row no-gutters pt-3">
        {topLevelExtras.map((item) => {
          if (
            item.SpecialityClassCode !== 'Opt' &&
            item.SpecialityClassCode !== 'Den'
          ) {
            return (
              <ExtrasUsageTile
                name={item.Description}
                benefitLimit={item?.ClaimableTotal}
                benefitUsed={item?.ClaimedAmount}
                key={`extra_${item.SpecialityClassCode}`}
                onClick={() => handleExtraClick(item, item.Description)}
              />
            );
          } else {
            return <></>;
          }
        })}
      </ReactSortable>
    </>
  );
};

const GeneralDentalExtrasTile = ({ generalDentalData, handleExtraClick, basicDental }) => {
  if (!generalDentalData) {
    return (
      <ExtrasUsageTile
        name={'General Dental'}
        benefitLimit={0}
        benefitUsed={0}
        size={'large'}
        key={`extra_${basicDental?.SpecialityClassCode}_1`}
        onClick={() =>
          handleExtraClick(
            basicDental,
            'General Dental',
          )
        }
      />
    )
  }

  return (
    <ExtrasUsageTile
      name={'General Dental'}
      data={generalDentalData}
      size={'large'}
      key={`extra_${generalDentalData.BenefitLimitCodeShort}`}
      onClick={() =>
        handleExtraClick(
          generalDentalData,
          'General Dental',
        )
      }
    />
  )
}

const MajorDentalExtrasTile = ({ majorDentalData, handleExtraClick, basicDental }) => {
  if (!majorDentalData) {
    return (
      <ExtrasUsageTile
        name={'Major Dental'}
        benefitLimit={basicDental?.ClaimableTotal}
        benefitUsed={basicDental?.ClaimedAmount}
        size={'large'}
        key={`extra_${basicDental?.SpecialityClassCode}_2`}
        onClick={() =>
          handleExtraClick(
            basicDental,
            'Major Dental',
          )
        }
      />
    )
  }

  return (
    <ExtrasUsageTile
      name={'Major Dental'}
      size={'large'}
      data={majorDentalData}
      key={`extra_${majorDentalData.BenefitLimitCodeShort}`}
      onClick={() =>
        handleExtraClick(
          majorDentalData,
          'Major Dental',
        )
      }
    />
  )
}

const OverallDentalTile = ({ overallDentalData, handleExtraClick, basicDental }) => {
  if (!overallDentalData) return null;

  return (
    <ExtrasUsageTile
      name={'Dental'}
      size={'large'}
      benefitLimit={overallDentalData?.ClaimableTotal}
      benefitUsed={overallDentalData?.ClaimedAmount}
      key={`extra_${overallDentalData?.BenefitLimitCodeShort}`}
      onClick={() =>
        handleExtraClick(basicDental, 'Dental')
      }
    />
  )
}



const OpticalExtrasTile = ({ opticalData, handleExtraClick }) => {
  if (!opticalData) return null;

  return (
    <ExtrasUsageTile
      name={'Optical'}
      data={opticalData}
      size={'large'}
      key={`extra_${opticalData.SpecialityClassCode}`}
      onClick={() =>
        handleExtraClick(opticalData, 'Optical')
      }
    />
  )
}

const ExtrasInfo = ({ topExtrasBars, handleExtraClick, currentUserBenefits }) => {
    const dispatch = useDispatch();
    const benefitLimitArray = useSelector((state) => state.benefitLimits);
    const product = useSelector((state) => state.product);

    return (
        <>
        <LabelledTitle label="Extras Cover" title={product?.extrasName} />
              {console.log({ topExtrasBars })}
              {Object.keys(benefitLimitArray).length !== 0 &&
              benefitLimitArray.constructor === Object &&
              topExtrasBars ? (
                <div
                  className="row no-gutters"
                  style={{
                    marginRight: '5px',
                  }}>
                  {!topExtrasBars?.dental?.HasOverallDental ? (
                    <>
                      <GeneralDentalExtrasTile
                        generalDentalData={topExtrasBars?.generalDental}
                        handleExtraClick={handleExtraClick}
                        basicDental={topExtrasBars.dental}
                      />
                      <MajorDentalExtrasTile
                        majorDentalData={topExtrasBars?.majorDental}
                        handleExtraClick={handleExtraClick}
                        basicDental={topExtrasBars.dental}
                      />
                    </>
                  ) : (
                    <OverallDentalTile
                      overallDentalData={topExtrasBars.dental.overall}
                      handleExtraClick={handleExtraClick}
                      basicDental={topExtrasBars?.dental}
                    />
                  )}
  
                  <OpticalExtrasTile
                    opticalData={topExtrasBars?.optical}
                    handleExtraClick={handleExtraClick}
                  />
                </div>
              ) : (
                <ReactPlaceholder
                  showLoadingAnimation
                  rows={3}
                  color={'#e5e4ed'}
                />
              )}
  
              <div className="">
                {currentUserBenefits && (
                  <>
                    <OutputExtraTiles
                      extrasData={currentUserBenefits}
                      handleExtraClick={handleExtraClick}
                    />
                  </>
                )}
                <div
                  style={{
                    fontSize: '14px',
                    margin: '10px 0',
                    fontWeight: 500,
                  }}>
                  Click the{' '}
                  <span>
                    <MdChevronRight
                      className="text-primary"
                      size={20}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </span>{' '}
                  to view the limits and sub-limits within the category
                </div>
                <div
                  style={{ fontSize: '14px', fontWeight: 500 }}
                  className="text-center ">
                  Check your benefits with the{' '}
                  <BenefitCalculator
                    onClick={() => {
                      gtagEvent({
                        screen: 'overview',
                        action: 'benefit_calculator',
                        label: 'Clicked benefit calculator button',
                        type: 'modal_open',
                      });
                      dispatch(setBenefitCalculatorModalVisible(true));
                    }}
                  />
                </div>
              </div>
  
              <div className="mt-2 d-none">
                <ShowAll />
              </div>
        </>
    )
}

export default ExtrasInfo;