import {Tab} from "react-bootstrap";
import React from "react";
import {CommunicationPreferences} from "../cards/CommunicationPreferences";
import {ChangePassword} from "../cards/ChangePassword";


export const Settings = () => {
    return (
        <Tab.Pane eventKey="settings">
            <div className="row">
                <div className="col-md-8 col-12">
                    <CommunicationPreferences/>
                </div>
                <div className="col-md-4 col-12 mt-4 mt-md-0">
                    <ChangePassword/>
                </div>
            </div>
        </Tab.Pane>
    );
};