import React from 'react';
import {Tab} from 'react-bootstrap';
import {MembershipDetails} from "../cards/MembershipDetails";
import {MembershipContributions} from "../cards/MembershipContributions";
import {BenefitPayments} from "../cards/BenefitPayments";
import {DiscountsAndRebates} from "../cards/DiscountsAndRebates";

const Details = () => {
    return (
        <Tab.Pane eventKey="details">
            <div className="row">
                <div className="col-12 col-md-7 col-lg-8">
                    <MembershipDetails/>
                </div>
                <div className="col-12 col-md-5 col-lg-4 mt-4 mt-md-0">
                    <MembershipContributions/>
                    <BenefitPayments/>
                    <DiscountsAndRebates/>
                </div>
            </div>
        </Tab.Pane>
    );
};

export default Details;
