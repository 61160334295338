import { ExpandDetails } from 'components/elements/buttons/ExpandDetails';
import CardAltDivision from 'components/elements/divisions/CardAltDivision';
import LabelledTitle from 'components/elements/labels/LabelledTitle';
import gtagEvent from 'libs/gtagEvent';
import React, { useEffect } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { MdCheck, MdChevronRight, MdClear } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CoverageIcon = ({ type }) => {
    let coverageIcon;

    // Set the icons dependant on coverage type
    switch (type) {
        case 'Included':
            coverageIcon = <MdCheck />;
            break;

        case 'Restricted':
            // TODO Find a icon for this, or style as an icon
            coverageIcon = 'R';
            break;

        case 'Excluded':
            coverageIcon = <MdClear />;
            break;
    }

    return coverageIcon;
};

const CoverageItem = ({ name, coverageType, from }) => {
    // Non-covered items will have a grayed out background and text
    let item;

    if (coverageType !== 'Excluded') {
        item = (
            <div className="d-flex align-items-center px-4">
                <div className="mr-2 mb-2 text-primary">
                    <CoverageIcon type={coverageType} />
                </div>
                <small
                    className="flex-grow-1 flex-shrink-1 mr-3"
                    style={{ paddingBottom: '.2rem' }}>
                    {name}
                </small>
                <small className="text-right flex-shrink-0">{from}</small>
            </div>
        );
    } else {
        item = (
            <CardAltDivision>
                <div className="d-flex align-items-center px-4">
                    <div className="mr-2 mb-2 text-muted">
                        <CoverageIcon type={coverageType} />
                    </div>
                    <small
                        className="flex-grow-1 flex-shrink-1 mr-3 text-muted"
                        style={{ paddingBottom: '.2rem' }}>
                        {name}
                    </small>
                    <small className="text-right flex-shrink-0">{from}</small>
                </div>
            </CardAltDivision>
        );
    }

    return item;
};

const CoverageList = ({ coveredItems, eventKey }) => {
    if (!coveredItems?.length) {
        return null;
    }

    const firstPart = coveredItems.slice(0, 4);
    const secondPart = coveredItems.slice(4);

    if (firstPart.length === 0) {
        return null;
    }

    return (
        <>
            <div className="d-flex flex-column">
                {firstPart
                    ? firstPart.map((cover) => {
                          return (
                              <CoverageItem
                                  key={cover.clinicalCategoryCode}
                                  name={cover.description}
                                  coverageType={
                                      cover.actualClinicalCategoryTypes
                                  }
                                  from={null}
                              />
                          );
                      })
                    : ''}
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="d-flex flex-column">
                    {secondPart.map((cover) => {
                        return (
                            <CoverageItem
                                key={cover.clinicalCategoryCode}
                                name={cover.description}
                                coverageType={cover.actualClinicalCategoryTypes}
                                from={null}
                            />
                        );
                    })}
                </div>
            </Accordion.Collapse>
        </>
    );
};


const DetailsUrlComp = ({ internal, url }) => {
    const gtag = () => {
        gtagEvent({
            screen: 'overview',
            action: 'hospital_details',
            label: 'Clicked on details',
        });
    };
    if (internal) {
        return (
            <Link to={url} onClick={gtag}>
                <Button className="p-1" variant="link-secondary">
                    DETAILS <MdChevronRight size={20} />
                </Button>
            </Link>
        );
    }
    return (
        <a href={url} target="_blank" rel="noopener noreferrer" onClick={gtag}>
            <Button className="p-1" variant="link-secondary">
                DETAILS <MdChevronRight size={20} />
            </Button>
        </a>
    );
};

const HospitalInfo = () => {
    const product = useSelector((state) => state.product);
    const clinicalCategories = useSelector((state) => state.hospital);
    
    useEffect(() => {
        console.log('clinicalCategories', clinicalCategories);
    },[clinicalCategories])

    let internalUrl = true;
    let detailsURL = '/member/cover';
    if (product?.hospitalDetailsURL ?? product?.bundleDetailsURL) {
        internalUrl = false;
        detailsURL = product?.hospitalDetailsURL || product?.bundleDetailsURL;
    }

    return (
        <>
            {/* Title */}
            <div className="px-4 mx-1">
                <LabelledTitle
                    label="Hospital Cover"
                    title={product?.hospitalName}
                />
            </div>

            {/* Excess Row */}
            <div className="d-flex align-items-center px-4 mx-1">
                <div className="mr-1 text-uppercase text-muted">Excess:</div>
                <strong className="mr-2">{product?.excess || '---'}</strong>
                <small
                    className="text-muted"
                    style={{
                        display:
                            product?.excess === 'No Excess' ? 'none' : 'block',
                    }}>
                    (Once per calendar year, per adult)
                </small>
            </div>

            {/* Action Row */}
            <div className="d-flex justify-content-between py-3 flex-wrap px-4 mb-1">
                <DetailsUrlComp internal={internalUrl} url={detailsURL} />
                <a
                    href={product?.base?.webpage}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        gtagEvent({
                            screen: 'overview',
                            action: 'hospital_brochure',
                            label: 'Clicked on product guide',
                        });
                    }}>
                    <Button className="p-1" variant="link-secondary">
                        PRODUCT GUIDE <MdChevronRight size={20} />
                    </Button>
                </a>
            </div>

            {/* Cover at a glance header */}
            <CardAltDivision>
                <div className="mb-2 d-flex justify-content-between align-items-center px-4 py-3">
                    <h5 className="mr-4 my-0 font-feature text-primary-dark-1 font-weight-semibold">
                        Your cover at glance:
                    </h5>
                    <div className="text-right">
                        {/*<Label
                            text="Cover from"
                        />*/}
                    </div>
                </div>
            </CardAltDivision>

            <div className="px-4 mb-1">
                <small>
                    Below is what you are covered for under your product. If you
                    are new to private health insurance or have transferred from
                    another product or fund some wait periods may apply. For
                    more information please{' '}
                    <a
                        href={product?.base?.webpage}
                        target="_blank"
                        rel="noopener noreferrer">
                        check here
                    </a>{' '}
                    or phone 1300 360 701.
                </small>
            </div>

            {/* Cover table */}
            <Accordion>
                <div className="mx-1">
                    {clinicalCategories ? (
                        <CoverageList
                            coveredItems={clinicalCategories}
                            eventKey="0"
                        />
                    ) : (
                        ''
                    )}
                </div>

                <div className="text-center mt-4">
                    <ExpandDetails eventKey="0" />
                </div>
            </Accordion>
        </>
    )
}

export default HospitalInfo;