import CardAltDivision from 'components/elements/divisions/CardAltDivision';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { setMembership } from 'store/membership/actions';
import api from '@qldtuh/tuh-uh-js-api';

export function CommunicationPreferences() {
    const membership = useSelector((state) => state.membership);
    const dispatch = useDispatch();
    const [commMethod, setCommMethod] = useState();

    function doCommunicationSelect(membership) {
        if (!membership?.CommunicationPreferences?.CommunicationPreference)
            return;

        const comms = membership.CommunicationPreferences.CommunicationPreference;
        const foundComm = comms.find((comm) => {
            return comm.CommunicationGroupId == 34;
        });
        return foundComm.CommunicationMediumId;
    }

    useEffect(() => {
        let comm = doCommunicationSelect(membership);
        setCommMethod(comm);
    }, [membership]);

    const [updatePrefState, updatePref] = useAsyncFn(async (commMethod) => {
        const access_token = getAccessToken();
        const memberid = getMembershipId();

        const data = {
            communicationmethod: parseInt(commMethod),
        };

        try {
            const response = await api
                .user(CONFIG, access_token)
                .membership.updateMembership(memberid, data);

            if (response?.status == 'error') {
                throw new Error(response.message);
            } else {
                dispatch(setMembership(response));
            }
        } catch (err) {
            console.log('ERROR');
            console.log(err.message);
        }
    });

    return (
        <Card className="py-4">
            <h1 className="px-4 mb-4 text-primary text-uppercase">
                Communication Preferences
            </h1>
            <div className="mx-1 mb-4">
                <CardAltDivision>
                    <div className="p-4 d-flex justify-content-between align-items-center flex-md-row flex-column">
                        <div className="mr-4 flex-shrink-1 col-12 col-md-6">
                            I would like to receive notifications and
                            communications via:
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Control
                                as="select"
                                value={commMethod}
                                onChange={(e) => {
                                    setCommMethod(e.target.value);
                                }}
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'updatecomms_dropdown',
                                        label: 'clicked contact us link from membership details',
                                    });
                                }}>
                                <option value="1">Email</option>
                                <option value="5">Text</option>
                                <option value="4">Post</option>
                            </Form.Control>
                        </div>
                    </div>
                </CardAltDivision>
            </div>
            <div className="mx-4">
                <Button
                    variant="secondary"
                    disabled={
                        doCommunicationSelect(membership) == commMethod ||
                        updatePrefState.loading
                    }
                    onClick={() => {
                        gtagEvent({
                            screen: 'membership',
                            action: 'update_communications',
                            label: 'Updated communications preference',
                        });
                        updatePref(commMethod);
                    }}>
                    {updatePrefState.loading ? (
                        <Spinner
                            animation="border"
                            role="status"
                            as="span"
                            size="sm"
                        />
                    ) : (
                        'Save'
                    )}
                </Button>
            </div>
        </Card>
    );
}
