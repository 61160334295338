import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import ClaimDetailsModal from 'components/modals/ClaimDetailsModal/ClaimDetailsModal';
import { allPersons, getSelectedUser } from 'libs/helpers';
import gtagEvent from 'libs/gtagEvent';
import { ClaimType } from 'types/claim';
import { StoreType } from 'store/types';

const Headers = [
    'SERVICE DATE',
    'CLAIMANT',
    'PROVIDER',
    'COST',
    'BENEFIT',
    'STATUS',
];

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
    tr:hover {
        cursor: pointer;
    }
`;

function convertStatus(status) {
    switch (status) {
        case 'Paid':
            return 'Claim finalised - paid';
        case 'Verified':
            return 'Claim processed  - awaiting payment';
        case 'Received':
        case 'Assessed':
            return 'Being processed';
    }
}

interface ClaimHistoryTableProps {
    className?: string;
    data?: ClaimType[];
}

const ClaimHistoryTable: React.FC<ClaimHistoryTableProps> = ({ className, data = [] }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const persons = allPersons(membership);

    const [claimHistoryModalVisible, setClaimHistoryModalVisible] =
        useState(false);
    const [claimHistoryRow, setClaimHistoryRow] = useState<ClaimType | undefined>(undefined);

    if (data.length === 0) {
        return (
            <>
                <div className="font-weight-medium">No claims history</div>
            </>
        );
    }

    return (
        <>
            <StyledTable className={className} responsive striped hover>
                <thead>
                    <tr>
                        {Headers.map((head) => (
                            <th key={head}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, idx) => (
                        <tr
                            key={`claim_row_${idx}`}
                            className={'table-link'}
                            onClick={() => {
                                setClaimHistoryRow(row);
                                setClaimHistoryModalVisible(true);
                            }}>
                            <td>
                                {moment(row.ServiceDate).format('DD/MM/YYYY')}
                            </td>
                            <td>
                                {
                                    getSelectedUser(persons, row.PersonId)
                                        ?.Person?.GivenName
                                }
                            </td>
                            <td>{row?.Provider?.Name}</td>
                            <td>${row?.Fee}</td>
                            <td>${row?.BenefitAmount}</td>
                            <td>{convertStatus(row?.ClaimLineStatus)}</td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>

            <ClaimDetailsModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'claimhistory',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setClaimHistoryModalVisible(false);
                }}
                show={claimHistoryModalVisible}
                align={'right'}
                data={claimHistoryRow}
            />
        </>
    );
};

export default ClaimHistoryTable;
