import {Button} from "react-bootstrap";
import {IoMdCalculator} from "react-icons/all";
import React from "react";

interface Props {
    onClick: () => void;
    style?: React.CSSProperties;
}

const BenefitCalculator: React.FC<Props> = ({onClick, style}) => {
    return <Button
        className="ml-0 px-2 text-primary-dark-1 d-flex align-items-center justify-content-center"
        variant="outline-gray"
        onClick={onClick}
        style={style}
    >
        <div className="mr-2">
            <IoMdCalculator size={20} style={{verticalAlign:'middle'}}/>
        </div>
        <div style={{fontSize:'13px'}}>
            Benefit Calculator
        </div>
    </Button>;
}

export default BenefitCalculator
