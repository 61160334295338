import React from 'react';
import { useSelector } from 'react-redux';
import { StoreType } from 'store/types';
import OverviewDesktop from 'components/sections/overview/OverviewDesktop';
import { useMediaQuery, useTheme } from '@material-ui/core';
import OverviewMobile from 'components/sections/overview/OverviewMobile';

const OverviewPage = () => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const product = useSelector((state: StoreType) => state.product);

    function hasExtras(product) {
        if (!product?.base?.type) {
            return;
        }

        if (
            product.base.type === 'extras' ||
            product.base.type === 'bundle' ||
            product.base.type === 'package'
        ) {
            return true;
        }
        return false;
    }

    function hasHospital(product) {
        if (!product?.base?.type) {
            return;
        }

        if (
            product.base.type === 'hospital' ||
            product.base.type === 'bundle' ||
            product.base.type === 'package'
        ) {
            return true;
        }
        return false;
    }

    const showExtras = hasExtras(product);
    const showHospital = hasHospital(product);

    const hospitalClasses = (showHospital === true)
            ? 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4'
            : 'col-md-12 mt-lg-0 mt-sm-4 col-lg-8';
    const extrasClasses = (showExtras === true)
            ? 'col-md-12 mt-lg-0 mt-sm-0 col-lg-8'
            : 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4';

    if (smallScreen) {
        return (
            <OverviewMobile hospitalClasses={hospitalClasses} extrasClasses={extrasClasses} />
        )
    }

    return (
        <OverviewDesktop hospitalClasses={hospitalClasses} extrasClasses={extrasClasses} />
    );
};

export default OverviewPage;
