import { useMediaQuery, useTheme } from '@material-ui/core';
import ProgressBar from 'components/elements/etc/ProgressBar';
import CoverIcon from 'components/elements/icons/CoverIcon';
import Checkmark from 'img/vector/Checkmark/comp';
import React from 'react';
import { MdChevronRight, MdMoreHoriz } from 'react-icons/all';
import styled from 'styled-components';

const ExtrasUsageTileContainer = styled.div`
    border: 2px solid #e5e4ed;
    border-radius: 2px;
    cursor: pointer;
    margin: -1px;
`;

const ExtrasUsageValueRemaining = styled.div`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.44px;
`;

const ExtrasUsageRemainingLabel = styled.div`
    font-size: 11px;
    color: #4a4a4a;
`;

interface Props {
    name: string;
    benefitLimit?: number;
    benefitUsed?: number;
    onClick: () => void;
    size?: string;
    data?: any;
}

const ExtrasUsageTile = ({
    name,
    benefitLimit,
    benefitUsed,
    onClick,
    size,
    data,
}: Props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    if (data) {
        // name = data?.Description;
        benefitLimit = parseInt(data?.ClaimableTotal);
        benefitUsed = parseInt(data?.ClaimedAmount);
    }

    let classes;
    if (!size || size === 'tile') {
        classes = 'col-12 col-md-4 col-lg-4 col-xl-3 p-3';
        return (
            <ExtrasUsageTileContainer onClick={onClick} className={classes}>
                <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <div className="d-flex align-items-center">
                        <div
                            className="font-feature font-weight-medium"
                            style={{ fontSize: '14px' }}>
                            {name}
                        </div>
                        <MdChevronRight
                            className="text-primary"
                            size={20}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </div>
                    <MdMoreHoriz style={{ color: '#848395' }} />
                </div>
            </ExtrasUsageTileContainer>
        );
    } else {
        // Top Large Tile
        classes = 'col-12 p-3';
        if (benefitLimit === 0) {
            classes = 'col-12 p-3 cursor-auto';
        }
        return (
            <ExtrasUsageTileContainer
                onClick={() => {
                    if (benefitLimit !== 0) {
                        onClick();
                    }
                }}
                className={classes}>
                <div className="row align-items-center">
                    <div className="pl-3 flex-shrink-1">
                        <CoverIcon name={name} width={smallScreen ? 40 : 60} height={smallScreen ? 40 : 60} />
                    </div>
                    <div className="col">
                        <div>
                            <div
                                className="font-feature font-weight-semibold d-inline-block"
                                style={{ fontSize: '18px' }}>
                                {name}
                            </div>
                            <MdChevronRight
                                className="text-primary"
                                size={20}
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        </div>
                        <div style={{ fontSize: '11px' }}>
                            Set benefits and sub-limits may apply.
                        </div>
                    </div>
                    <div className="col-6">
                        {((benefitLimit && benefitUsed) && benefitLimit !== 0 ) ? (
                            <div className="mt-3 d-flex justify-content-between">
                                <div className="d-flex align-items-end flex-wrap">
                                    <ExtrasUsageValueRemaining
                                        className="font-feature font-weight-medium mr-1"
                                        style={{
                                            fontSize: smallScreen ? '16px' : '24px',
                                        }}>
                                        $
                                        {(benefitLimit - benefitUsed)?.toFixed(
                                            2,
                                        )}
                                    </ExtrasUsageValueRemaining>

                                    <ExtrasUsageRemainingLabel
                                        className="font-feature font-weight-medium"
                                        style={{
                                            fontSize: '10px',
                                        }}>
                                        REMAINING
                                    </ExtrasUsageRemainingLabel>
                                </div>
                                {!smallScreen 
                                    && <div
                                        style={{
                                            fontSize: '12px',
                                            color: '#848395',
                                        }}>
                                            ${benefitLimit}
                                        </div>
                                }
                            </div>
                        ) : (
                            ''
                        )}
                        {benefitLimit !== 0 ? (
                            <ProgressBar
                                className="mt-2"
                                value={benefitUsed}
                                maxValue={benefitLimit}
                            />
                        ) : (
                            <span style={{ fontSize: '14px' }}>
                                No annual limits{' '}
                                <Checkmark
                                    style={{
                                        verticalAlign: 'middle',
                                        color: '#9C99D9',
                                    }}
                                />
                            </span>
                        )}
                    </div>
                </div>
            </ExtrasUsageTileContainer>
        );
    }
};

export default ExtrasUsageTile;
